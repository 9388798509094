import React from "react";

import "./App.css";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import RecentWork from "./components/RecentWork";
import Footer from "./components/Footer";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Contact from "./components/Contact";
function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <RecentWork /> <Footer />
              </>
            }
          />

          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
