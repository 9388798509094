import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Project2 from "../img/project-1.png";
import Project5 from "../img/rent.png";
import Project4 from "../img/covid19.png";
import Project1 from "../img/ecom.png";
import project3 from "../img/bank.jpg";

const RecentWork: React.FC = (): JSX.Element => {
  return (
    <div className="grid md:grid-cols-3 grid-cols-1 md:m-[5vw] m-[4vw]">
      <div className="rounded-2xl cursor-pointer overflow-hidden text-center relative md:mx-3 my-3">
        <img
          src={Project1}
          alt="Project1"
          className="recentwork z-10 scale-100 top-0 left-0 ease-in-out duration-200"
        />
        <figcaption
          className={`opacity-0 hover:opacity-100  bg-[#141C3A] absolute w-[100%] h-[100%] text-white ease-in-out duration-500 text-l px-[4rem] font-medium top-0 left-0 flex flex-col justify-center items-center`}
        >
          <h1>Full Stack Ecommerce website Made using MERN stack.</h1>
          <a
            className="mt-5"
            href="https://github.com/sagarkhadka12345/ecommerce-mern"
          >
            <button className="flex p-3 rounded-md border justify-center items-center">
              <span
                onClick={() =>
                  (window.location.href =
                    "https://github.com/sagarkhadka12345/ecommerce-mern")
                }
              >
                Visit GitHub Page
              </span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="pl-1 h-5 w-5 "
              />
            </button>
          </a>
          <a
            className="mt-5"
            href="https://web-production-3da6.up.railway.app/"
          >
            <button className="flex p-3 rounded-md border justify-center items-center">
              <span>Visit Website</span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="pl-1 h-5 w-5 "
              />
            </button>
          </a>
        </figcaption>
      </div>

      <div className="rounded-2xl cursor-pointer overflow-hidden text-center relative md:mx-3 my-3">
        <img
          src={project3}
          alt="Project3"
          className="recentwork z-10 scale-100 top-0 left-0 ease-in-out duration-200"
        />
        <figcaption
          className={`  opacity-0 hover:opacity-100  bg-[#141C3A] absolute w-[100%] h-[100%] text-white ease-in-out duration-500 text-l px-[4rem] font-medium top-0 left-0 flex flex-col justify-center items-center`}
        >
          <h1>Bank Management System - uses php and mysql</h1>
          <a
            className="mt-5"
            href="https://github.com/sagarkhadka12345/ecommerces-mern"
          >
            <button className="flex p-3 rounded-md border justify-center items-center">
              <span
                onClick={() =>
                  (window.location.href =
                    "https://github.com/sagarkhadka12345/ecommerces-mern")
                }
              >
                Show Source Code
              </span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="pl-1 h-5 w-5 "
              />
            </button>
          </a>
        </figcaption>
      </div>

      <div className="rounded-2xl cursor-pointer overflow-hidden text-center relative md:mx-3 my-3">
        <img
          src={Project4}
          alt="project 4"
          className="recentwork z-10 scale-100 top-0 left-0 ease-in-out duration-200"
        />
        <figcaption
          className={`  opacity-0 hover:opacity-100  bg-[#141C3A] absolute w-[100%] h-[100%] text-white ease-in-out duration-500 text-l px-[4rem] font-medium top-0 left-0 flex flex-col justify-center items-center`}
        >
          <h1>Covid-19 tracking website</h1>
          <a className="mt-2" href="https://covidtracker2078.netlify.app/">
            <button className="flex p-3 rounded-md border justify-center items-center">
              <span
                onClick={() =>
                  (window.location.href =
                    "https://covidtracker2078.netlify.app/")
                }
              >
                Visit Website
              </span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="pl-1 h-5 w-5 "
              />
            </button>
          </a>
        </figcaption>
      </div>
    </div>
  );
};

export default RecentWork;
