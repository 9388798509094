import React from "react";
import HeroImg from "../img/hero.svg";
import Designer from "../img/designer.svg";
import FrontEnd from "../img/frontend.svg";
import BackEnd from "../img/back-end.svg";

const Hero:React.FC = ():JSX.Element => {
  return (
    <>
      <div className="wrapper flex flex-wrap  header ml-[5vw] md:ml-[15vw] mt-[10vh]">
        <div className="static-txt min-w-[135px] min-h-[90px] sm:text-[60px] text-[50px] mr-4 ">
          I'm a
        </div>
        <ul className="dynamic-txts  h-[90px] sm:leading-[90px] leading-[80px]  overflow-hidden">
          <li className="list-none text-[#FC6D6D] sm:text-[60px] text-[50px] font-medium relative top-0">
            <span className='relative my-[5px] leading-[90px] after:content-[""]  after:absolute after:left-0 after:bottom-0  after:h-[105%] after:w-full after:bg-white '>
              Designer
            </span>
          </li>
          <li className="list-none text-[#FC6D6D] sm:text-[60px] text-[50px] font-medium relative top-0">
            <span className='relative my-[5px] leading-[90px] after:content-[""]  after:absolute after:left-0 after:bottom-0 after:h-[105%] after:w-full after:bg-white'>
              Developer
            </span>
          </li>
          <li className="list-none text-[#FC6D6D] sm:text-[60px] text-[50px] font-medium relative top-0">
            <span className='relative my-[5px] leading-[90px] after:content-[""]  after:absolute after:left-0 after:bottom-0 after:h-[105%] after:w-full '>
              Freelancer
            </span>
          </li>
          <li className="list-none text-[#FC6D6D] sm:text-[60px] text-[50px] font-medium relative top-0">
            <span className='relative my-[5px] leading-[90px] after:content-[""]  after:absolute after:left-0 after:bottom-0  after:h-[105%] after:w-full '>
              Programmer
            </span>
          </li>
        </ul>
      </div>
      <div className="sub-header  text-2xl  ml-[5vw] md:ml-[15vw] mt-[3vh]">
        I am a designer and code beautiful , fast and creative websites.
      </div>
      <div className="img-container h-[25rem]  flex justify-center items-center">
        <img src={HeroImg} />
      </div>
      <div className="bg-[#7510F7] flex flex-col pt-[10vh] pb-[14rem] md:px-[25vw] px-[15vw]">
        <h2 className="font-bold w-[100%] my-5 flex justify-center ">
          Hi, I'm Sagar khadka. Nice to meet you.
        </h2>
        <p className="text-center mt-5 leading-8">
          I am currently studying BCA(Bachelors in Computer Application). I have 
          knowledge in HTML, CSS, Javascript. I have also worked on project using PHP
          and relational database MySql. I am proficient in MERN stack (full stack development)
          where  I use MongoDB for database , Expressjs for backend data , React for Front end framework and Nodejs for webserver. 
          
        </p>
      </div>
      <div className="flex bg-white flex-wrap text-center rounded-2xl px-5 md:mx-[6vw] mx-[8vw] mt-[-10rem] shadow-md">
        <div className="md:flex-1 py-[30px] px-[15vw] sm:px-[25vw] md:pl-[05vw] sm:mt-4 md:mt-1 md:mx-0  md:px-[20px]  md:border-r-2 border-b-2 border-[#E6ECF8]  ">
          <figure className="flex justify-center">
            <img src={Designer} />
          </figure>
          <h1 className="flex justify-center my-5 font-extrabold leading-normal">
            Designer
          </h1>
          <p className="my-10">
            I create simple and clean designs
          </p>
          <p className="text-indigo-800 mt-1 mb-2 ]">Things I  design:</p>
          <p className="mb-10">UX, UI, Web, Mobile, Apps, Logos</p>
          <p className="text-indigo-800">Design Tools:</p>
          <ul>
            <li>Pen and Paper</li>
            <li>Figma</li>
            <li>Sketch</li>
          </ul>
        </div>
        <div className="md:flex-1 py-[30px] px-[15vw] sm:px-[20vw] md:px-[5vw] md:border-r-2 border-b-2 border-[#E6ECF8] md:mt-1 " >
          <figure className="flex justify-center">
            <img src={Designer} />
          </figure>
          <h1 className="flex justify-center my-5 font-extrabold leading-normal">
            Front end developer
          </h1>
          <p className="my-10">
            I create clean and reponsive front End of websites.
          </p>
          
          <p className="text-indigo-800">Technologies I use:</p>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>React.js</li>
          </ul>
        </div>
        <div className="md:flex-1 py-[30px] px-[15vw] sm:mx-[5vw] md:px-[20px]  ">
          <figure className="flex justify-center md:mt-1 ">
            <img src={Designer} />
          </figure>
          <h1 className="flex justify-center my-5 font-extrabold leading-normal">
            Back-End Developer
          </h1>
          <p className=" flex justify-center text-center  my-10">
            I create fast and secure Backend structure of Website
          </p>
          <p className=" flex justify-center text-indigo-800">Technologies I use:</p>
          <ul className="flex flex-col items-center" >
            <li>Node.js</li>
            <li>Express.js</li>
            <li>MongoDB</li>
            <li>MySql</li>
            
          </ul>
        </div>
        
      </div>
    </>
  );
};

export default Hero;
