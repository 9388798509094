import React, { FormEvent, useState } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faLinkedin,
  faGit,
  faGitAlt,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
const Contact: React.FC = (): JSX.Element => {
  const [mail, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const submitHandler = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    name !== "" &&
      message !== "" &&
      name !== "" &&
      emailjs
        .send(
          "service_1tmoz9a",
          "template_mp7euvb",
          {
            message: message,
            to_email: "Sagar.khadka+portfolio20011@gmail.com",
            from_name: name,
            from_email: mail,
          },
          "yurRoehrdXBifMj3E"
        )
        .then(
          function (response) {
            alert("Email sent Successfully");
          },
          function (err) {
            alert("Error in sending email");
          }
        );
  };
  return (
    <>
      <div className="bg-[#7510F7] md:h-auto h-auto md:flex ">
        <form
          className="w-full h-max flex justify-center"
          onSubmit={submitHandler}
        >
          <div className="bg-white border-2 shadow-sm border-gray-400 m-[3rem] p-8 text-right md:pt-2 ">
            <div className="flex flex-col py-1 md:py-4 my-2 md:my-4 border-b-2 text-left md:text-xl ">
              Contact me:
            </div>

            <div className="flex">
              <div className="relative flex items-center">
                Name:
                <input
                  className="border my-2 py-1"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={faMessage}
                  className="absolute right-2"
                  color={"#7510F7"}
                />
              </div>
              <div className="relative flex items-center">
                Email:{" "}
                <input
                  className="border my-2 py-1"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  color={"#7510F7"}
                />
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="absolute right-2"
                  color={"#7510F7"}
                />
              </div>
            </div>
            <div className="flex flex-col text-left justify-center">
              <span className="mt-[3vh]">Message:</span>{" "}
              <textarea
                className="border-2 my-2 mb-4 resize-none h-[20vh]"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="flex   justify-center">
              <button
                type="submit"
                className="border-2 p-2 border-gray bg-[#7510F7] hover:bg-blue-500"
              >
                Submit
              </button>
            </div>
            <div className="flex justify-between h-max w-full mt-8 border border-black  shadow-md text-indigo-800 px-8 pt-4">
              <div className="flex flex-col justify-between bg-white  text-left h-max mr-24 w-full ">
                <div className="h-8 py-2">Number: 9864667842</div>
                <div className="h-8 py-4"> Kapan, Kathmandu</div>
              </div>
              <div className="flex flex-col justify-between bg-white  text-left h-max  w-full ">
                <a
                  className="my-2"
                  href="https://www.linkedin.com/in/sagar-khadka-0083bb216/"
                >
                  <FontAwesomeIcon icon={faLinkedin} fontSize={24} />
                </a>
                <a className="mb-4" href="https://github.com/sagarkhadka12345">
                  <FontAwesomeIcon icon={faGithub} fontSize={24} />
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
