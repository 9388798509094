import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavBar: React.FC = (): JSX.Element => {
  const [screenSize, setScreenSize] = useState("notMobile");

  const location = useLocation();

  return (
    <nav
      className={
        location.pathname === "/contact"
          ? `bg-[#7510F7] flex p-2 sm:p-4  text-[#14163a] flex-col sm:flex-row `
          : "bg-slate-100 flex p-2 sm:p-4  text-white flex-col sm:flex-row "
      }
    >
      <div className="ml-8 mr-[15vw] md:mr-[50vw] mt-6 sm:mt-1 mb-12 sm:mb-0 "></div>
      <div
        className="sm:hidden block flex-col sm:flex-row ml-auto cursor-pointer absolute top-[2rem] right-[2rem]"
        onClick={() =>
          screenSize === "mobile"
            ? setScreenSize("notMobile")
            : setScreenSize("mobile")
        }
      >
        <div className="w-9 h-1 bg-slate-900 my-1.5 relative duration-200 line1 "></div>
        <div className="w-9 h-1 bg-slate-900 my-1.5 relative duration-200 line2 "></div>
        <div className="w-9 h-1 bg-slate-900 my-1.5 relative duration-200 line3"></div>
      </div>
      <ul
        id={screenSize}
        className=" ease-linear  text-2xl flex sm:flex-1  sm:flex-row justify-evenly   flex-col "
      >
        <li
          className={
            location.pathname === "/contact"
              ? "my-2 pl-4 sm:my-0 cursor-pointer hover:text-white"
              : "my-2 pl-4 sm:my-0 cursor-pointer text-black hover:text-[#7510F7]"
          }
        >
          <Link to="/"> Home</Link>
        </li>
        <li
          className={
            location.pathname === "/contact"
              ? "my-2 pl-4 sm:my-0 cursor-pointer hover:text-white"
              : "my-2 pl-4 sm:my-0 cursor-pointer text-black hover:text-[#7510F7]"
          }
        >
          <Link to="/contact"> Contact me</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
