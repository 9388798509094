import {
  faFacebook,
  faGithub,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Footer: React.FC = (): JSX.Element => {
  return (
    <footer className="w-[100%] bg-[#7510F7]">
      <div className="mt-6 pt-4 flex justify-center items-center text-3xl font-md ">
        SK
      </div>
      <div className="px-5 text-center mb-4 mt-2">
        Living, learning, & leveling up one day at a time.
      </div>
      <div className="flex justify-around sm:mx-[30vw] mx-[20vw] my-4">
        <a href="https://www.linkedin.com/in/sagar-khadka-0083bb216/">
          <div className="rounded-[100%] w-10 h-10 border-solid border-2 border-gray-400 flex justify-center items-center ">
            <FontAwesomeIcon icon={faLinkedinIn} className="w-7 h-7 m-[2px] " />
          </div>
        </a>
        <a href="twitter">
          <div className="rounded-[100%] w-10 h-10 border-solid border-2 border-gray-400 flex justify-center items-center ">
            <FontAwesomeIcon icon={faTwitter} className="w-7 h-7 m-[2px] " />
          </div>
        </a>
        <a href="facebook">
          <div className="rounded-[100%] w-10 h-10 border-solid border-2 border-gray-400 flex justify-center items-center ">
            <FontAwesomeIcon icon={faFacebook} className="w-7 h-7 m-[2px] " />
          </div>
        </a>
        <a href="https://github.com/sagarkhadka12345">
          <div className="rounded-[100%] w-10 h-10 border-solid border-2 border-gray-400 flex justify-center items-center ">
            <FontAwesomeIcon icon={faGithub} className="w-7 h-7 m-[2px] " />
          </div>
        </a>
      </div>
      <div className="flex justify-center items-center mt-8 pb-6">
        Made By Sagar khadka &copy; &nbsp; 2022
      </div>
    </footer>
  );
};

export default Footer;
